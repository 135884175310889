import { parseWithZod } from '@conform-to/zod';
import { Form, Link, useNavigate } from '@remix-run/react';
import type { RouteHandle } from '@remix-run/server-runtime';
import { useMutation } from '@tanstack/react-query';
import type React from 'react';
import { type FC, type ForwardedRef, forwardRef, useRef, useState } from 'react';
import type { DateRange } from 'react-day-picker';
import { toast } from 'sonner';
import { z } from 'zod';
import { analytics } from '~/components/analytics/segment-tracker.client.js';
import { ErrorList } from '~/components/form/forms.js';
import { FormatDate } from '~/components/format-date.tsx';
import { StudentStoriesSection } from '~/components/landing/animated-student-stories.tsx';
import { FAQSection } from '~/components/landing/faq.tsx';
import { StorageScholarsSection } from '~/components/landing/storage-scholars.tsx';
import { WhatAreYouWaitingForSection } from '~/components/landing/what-are-you-waiting-for.tsx';
import { Button } from '~/components/ui/button.tsx';
import { Calendar } from '~/components/ui/calendar.tsx';
import { Card } from '~/components/ui/card.tsx';
import { Dialog, DialogContent, DialogFooter, DialogTitle } from '~/components/ui/dialog.js';
import { Icon } from '~/components/ui/icon.tsx';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '~/components/ui/input-otp.js';
import { Input } from '~/components/ui/input.tsx';
import { Popover, PopoverContent, PopoverTrigger } from '~/components/ui/popover.tsx';
import { StatusButton } from '~/components/ui/status-button.js';
import { cn, useDebounce } from '~/utils/misc.ts';
import { useGoogleMapJsLoader } from '~/utils/use-google-maps-loader.ts';
import {
  useAutocompleteArrowsInput,
  useLocationAutocompletePlaces,
  useLocationAutocompletePredictions,
} from '~/utils/use-location-autocomplete.ts';
import { useIsAuthenticated } from '~/utils/user.js';
import { postCheckListingsAvailability } from '../resources+/check-listings-availability.tsx';
import {
  requestListingsAvailability,
  verifyListingsAvailability,
} from '../resources+/request-listings-availability.tsx';
import type { LandingHandle } from './_layout.tsx';

export const handle = {
  mode: 'guest',
  mainClass: 'flex flex-col items-center',
} satisfies RouteHandle & LandingHandle;

export default function GuestLandingRoute() {
  return (
    <>
      <div className="relative w-full max-w-[1440px] px-[22px] sm:px-4 md:px-8">
        <div className="flex h-[min(900px,calc(100vh-var(--landing-header-height)))] w-full flex-col items-center pt-40 sm:justify-center sm:pt-0">
          <HeroSection />
        </div>
      </div>
      <HowItWorksSection />
      <StorageScholarsSection />
      <StudentStoriesSection />
      <WhatAreYouWaitingForSection />
      <FAQSection />
    </>
  );
}

function HeroSection() {
  return (
    <>
      <section className="flex w-full sm:hidden">
        <div className="w-[340px] rounded-md border-border bg-white pt-8 pr-8 pb-4 pl-[14px] shadow-md">
          <h1 className="font-bold text-[34px] leading-[normal] duration-500">
            Find your <br /> perfect sublet
          </h1>
          <div className="h-2" />
          <p className="fade-in slide-in-from-bottom animate-in fill-mode-both text-lg delay-200 duration-500">
            Get personalized matches with <br /> university-verified hosts
          </p>
          <div className="h-8" />
          <GuestOnboarding className="fade-in slide-in-from-bottom animate-in fill-mode-both delay-300 duration-500" />
        </div>
        <img
          src="data:image/jpeg;base64,/9j/4QDURXhpZgAASUkqAAgAAAAHAA4BAgALAAAAcgAAABIBAwABAAAAAQAAABoBBQABAAAAYgAAABsBBQABAAAAagAAACgBAwABAAAAAgAAABMCAwABAAAAAQAAAGmHBAABAAAAfgAAAAAAAABIAAAAAQAAAEgAAAABAAAAY29tcHJlc3NlZAAABgAAkAcABAAAADAyMTABkQcABAAAAAECAwAAoAcABAAAADAxMDABoAMAAQAAAP//AAACoAQAAQAAAPoAAAADoAQAAQAAAAkBAAAAAAAA/9sAQwBQUFBQVVBaZGRafYd4h325qpubqrn/yNfI18j//////////////////////////////////////////////////9sAQwFQUFBQVVBaZGRafYd4h325qpubqrn/yNfI18j//////////////////////////////////////////////////8IAEQgBCQD6AwEiAAIRAQMRAf/EABgAAQEBAQEAAAAAAAAAAAAAAAABAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAPUsIoiwAiiUAAAAACiLg1eeygA1KMrACAEBCxCsjdzslAABw78Cd/P3NAA0CSwAASwAijLQlg0yNMjTMJ59ci9uGz1a47Ns03c0SwSiKIsAAIoiiKIomdjg7Djeo56sGs7OeuVO7nsoAAEoiiAAAAAAAksGs6OEZOnTj2NgAZcjo849Dzw9OvN2NgAAAAAksGs6PNLDfXls63mNsC+fryMASwvp8voOzA2wNsDbI0zDczDUgtzo8qU1jfE0xDo5U6OY6OY6TA1rmOrkOt5U6XmOjmOkxk6TlDv183oOus6PHc03y68zK7ObvDlOuDCiKItM3Yy1TLYyozncOc64Ho49zrrOjxWDrrHczroM56Q83LvxMtDLUHfj6Q6Dm2MtU5ug5Z74Oc6jld4N759DwyDr6fJ6TsQSyzhy3wl6XlTeJDp6vJ6jrARCsjTI0zSoLmi3I8ZDXq83pOsLGbDhw9HNeTqjlOuR6fP6DoBKMyiUCgUigDxZsOno8vorvnGTrOcJhmNIW5sHo83VO7nTaUkAnM6uA9F8+juxoqK8Ih15dCzQyozNFy0jLUM6Um86rtZUkBz6ZOObg3vHcvSWiDxIi9OXU2qsiJLFCEsLZRrNrtc1MgvPfE5Zovo83Y9KWgTwCXXXns2gEIRQggtgtzquyEzM5N8rzLc7Jcw9N406uQ5WU1qDYEQCVLAlKQtzquwThjeCZ1ktzS5sN3NKD//EAB0QAAIDAAMBAQAAAAAAAAAAAAECABESECBAMFD/2gAIAQEAAQIA/ZJB8V3fxaLB5R8DGiwHxVXwZmYMrA+oljWRFMv0EZxnNc35676DA+8H8Adr1rWteE8CL1MY3erDL4TwIvUxuqxfCeBAda1os3URTrWta1d3rWtX1Lb3ve9Xd3e973veta1vewwI5EMbg+dYsHIhjc1VeNYsHUjIXBWj2rOc5znOaznICwciCZxmirdhFXGc5znOSlVnMHRYvQxpVVUWL9aMXopToY0u7JtYv2oDlYnQx4Zd3Fi+NYnQxoRVVSxfGsXoY3SiBF8axebMbqYIvwu9XfZYp1rWiZcswENrXUnWtBgewN/RYOpB5AXuJUr5CL1aMbWL8B9hB1YkwFD3X7CDq/FRCD1EH2EEMJssx4MUhta1wIPsIIYTZPJ4Bu74H3EWGN0Es8DyiLGjfAdP/8QAFhAAAwAAAAAAAAAAAAAAAAAAEYCQ/9oACAEBAAM/AHdMY//EABQRAQAAAAAAAAAAAAAAAAAAAID/2gAIAQIBAT8ACH//xAAUEQEAAAAAAAAAAAAAAAAAAACA/9oACAEDAQE/AAh//9k="
          alt="home"
          // @ts-expect-error
          fetchpriority="high"
          className="-z-10 absolute inset-0 h-full w-full bg-primary/10 object-cover blur-md"
        />
        <picture>
          <source srcSet="/img/landing/home.webp" type="image/webp" />
          <img
            src="/img/landing/home.jpeg"
            alt="home"
            // @ts-expect-error
            fetchpriority="high"
            className="-z-10 absolute inset-0 h-full w-full object-cover"
          />
        </picture>
      </section>
      <section className="hidden w-full flex-row items-center justify-center gap-8 sm:flex lg:justify-between lg:overflow-hidden lg:pl-4">
        <div className="relative h-max w-fit pb-64">
          <div
            className={cn(
              'flex flex-col pb-3 font-bold text-4xl sm:text-6xl lg:w-max min-[1064px]:text-7xl',
            )}
          >
            <h1 className="text-center lg:text-start">
              Find your <br className="hidden lg:inline" /> perfect sublet
            </h1>
          </div>
          <div
            className={cn(
              'flex w-full flex-col pb-6 text-2xl',
              'fade-in slide-in-from-bottom animate-in fill-mode-both delay-200 duration-500',
            )}
          >
            <h2 className="text-center lg:text-start">
              Get personalized matches with <br className="hidden lg:inline" /> university-verified
              hosts
            </h2>
          </div>
          <GuestOnboarding className="fade-in slide-in-from-bottom animate-in fill-mode-both delay-300 duration-500" />
        </div>
        <div className="relative hidden flex-row gap-4 self-end overflow-hidden pr-12 pb-2 lg:flex">
          <div className="flex w-80 flex-col gap-4">
            <ExampleListingCard
              title="Colby's Studio"
              subtitle="USC '26"
              imageUrl="/img/landing/listings/kitchen-1.jpeg"
              avatarUrl="/img/landing/host/colby.jpeg"
              universityUrl="/img/landing/universities/usc-trojans.png"
            />
            <ExampleListingCard
              title="Grace's Room"
              subtitle="Northwestern '24"
              imageUrl="/img/landing/listings/bedroom-1.jpeg"
              avatarUrl="/img/landing/host/grace.jpeg"
              universityUrl="/img/landing/universities/northwestern.png"
            />
            <ExampleListingCard
              title="Skylar's Place"
              subtitle="Columbia '24"
              imageUrl="/img/landing/listings/kitchen-2.jpeg"
              avatarUrl="/img/landing/host/skylar.jpeg"
              universityUrl="/img/landing/universities/columbia-university.png"
            />
          </div>
          <div className="relative w-80">
            <div className="-bottom-6 absolute right-0 flex rotate-6 flex-col gap-4">
              <ExampleListingCard
                title="Luis' Cozy Apartment"
                subtitle="Northwestern '25"
                imageUrl="/img/landing/listings/bedroom-3.jpeg"
                avatarUrl="/img/landing/host/luis.jpeg"
                universityUrl="/img/landing/universities/northwestern.png"
              />
              <ExampleListingCard
                title="Leah's House"
                subtitle="Harvard '25"
                imageUrl="/img/landing/listings/bedroom-2.jpeg"
                avatarUrl="/img/landing/host/leah.jpeg"
                universityUrl="/img/landing/universities/harvard-university.png"
              />
              <ExampleListingCard
                title="Ty's Apartment"
                subtitle="Northwestern '25"
                imageUrl="/img/landing/listings/bedroom-4.jpeg"
                avatarUrl="/img/landing/host/ty.jpeg"
                universityUrl="/img/landing/universities/northwestern.png"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

type ExampleListingCardProps = {
  imageUrl: string;
  title: string;
  subtitle: string;
  universityUrl: string;
  avatarUrl: string;
  className?: string;
};
function ExampleListingCard(props: ExampleListingCardProps) {
  const { title, subtitle, imageUrl, avatarUrl, universityUrl, className } = props;
  return (
    <Card className={cn('h-72 w-80 overflow-hidden', className)}>
      <figure className="group relative flex h-full w-full items-center justify-center overflow-hidden">
        <img
          className="h-full w-full bg-gray-200 object-cover transition-transform group-hover:scale-110"
          src={imageUrl}
          alt="example listing"
        />
        <figcaption className="absolute right-0 bottom-0 left-0 flex items-center gap-3 bg-gradient-to-t from-black/70 to-transparent pt-6 pb-6 pl-4 text-white">
          <div className="relative">
            <img
              src={avatarUrl}
              alt="example listing host"
              className="h-[4.5rem] w-[4.5rem] rounded-full object-cover"
            />
            <img
              src={universityUrl}
              alt="example listing host"
              className="-bottom-3 -right-3 absolute h-[2.5rem] w-[2.5rem] object-contain"
            />
          </div>
          <div>
            <p className="font-semibold text-sm leading-3">{title}</p>
            <p className="text-sm leading-3">
              {subtitle}
              <Icon name="blue-checkmark" className="h-6 w-6" />
            </p>
          </div>
        </figcaption>
      </figure>
    </Card>
  );
}

function GuestOnboarding({ className }: { className?: string }) {
  const authenticated = useIsAuthenticated();
  const navigate = useNavigate();
  return (
    <div
      className={cn(
        'mb-6 flex flex-col gap-2 sm:mb-0 sm:items-center sm:gap-6 lg:items-start',
        className,
      )}
    >
      {authenticated ? (
        <OnboardingButton onClick={() => navigate('/app/preferences')} />
      ) : (
        <OnboardingForm />
      )}
      <div className="flex items-start justify-start gap-6 pl-6 sm:items-center sm:justify-center sm:pl-0 md:pl-6 lg:justify-end">
        <Link to="/signup?type=guest-partial" prefetch="intent" className="text-gray-500 text-sm">
          verify now, match later
        </Link>
      </div>
    </div>
  );
}

const OnboardingButton = forwardRef(
  ({ onClick }: { onClick?: () => void }, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <button
        ref={ref}
        className={cn(
          'grid w-full max-w-[620px] grid-cols-[2fr,44px] items-center gap-4 rounded-full border bg-white py-[2px] pl-6 text-center font-semibold text-base shadow-outer-border hover:bg-gray-background/80 hover:delay-0 sm:grid-cols-[2fr,2px,2fr,2px,2fr,44px] sm:justify-items-center md:w-[620px] md:max-w-none',
          'fade-in slide-in-from-bottom animate-in fill-mode-both text-gray-500 delay-300 duration-500',
        )}
        onClick={onClick}
      >
        <div className="py-2 text-start sm:place-self-start sm:self-center">Anywhere</div>
        <div className="hidden h-[46px] w-[1px] py-2 sm:block">
          <div className="h-full w-[1px] bg-gray-200" />
        </div>
        <div className="hidden py-2 text-center sm:block">Check-in</div>
        <div className="hidden h-[46px] w-[1px] py-2 sm:block">
          <div className="h-full w-[1px] bg-gray-200" />
        </div>
        <div className="hidden py-2 text-center sm:block">Check-out</div>
        <Button
          asChild
          variant="gradient"
          aria-label="Search"
          className="pointer-events-auto ml-[2px] flex h-[38px] w-[38px] items-center justify-center rounded-full p-0"
        >
          <div>
            <Icon name="magnifying-glass" className="h-6 w-6" />
          </div>
        </Button>
      </button>
    );
  },
);

const howItWorksSlides = [
  <HowItWorksStep
    key="slide-1"
    className="flex-1"
    image={<Icon name="blue-checkmark" className="h-8 w-8" />}
    title="Verify"
    description="We verify that all users are University-affiliated."
  />,
  <HowItWorksStep
    key="slide-2"
    className="flex-1"
    image={<img src="/img/landing/icons/match.png" alt="match icon" className="h-8 w-8" />}
    title="Match"
    description="Share your preferences and we send you matches."
  />,
  <HowItWorksStep
    key="slide-3"
    className="flex-1"
    image={<img src="/img/landing/icons/sublet.png" alt="sublet icon" className="h-8 w-8" />}
    title="Sublet"
    description="Pick the perfect place. It’s never been easier."
  />,
];

function HowItWorksSection() {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const handleScroll = useDebounce((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const container = scrollContainerRef.current;
    if (!container) return;

    setSlideIndex(
      Math.max(
        Math.min(
          Math.floor((container.scrollLeft / container.scrollWidth) * howItWorksSlides.length),
          howItWorksSlides.length - 1,
        ),
        0,
      ),
    );
  }, 30);

  return (
    <section className="flex w-full items-center justify-center bg-hostU-blue-gradient px-6 py-10 md:px-8 md:py-20">
      <div className="w-full max-w-[1440px] text-white">
        <p className="font-semibold text-2xl">How it works</p>
        <div
          className="hide-scrollbar flex snap-x snap-mandatory gap-6 overflow-x-auto overflow-y-hidden pt-4 pb-3 sm:flex-col sm:overflow-hidden md:flex-row md:gap-10 md:pt-6 lg:gap-14"
          onScroll={handleScroll}
          ref={scrollContainerRef}
        >
          {howItWorksSlides}
        </div>
        <div className="flex items-center justify-center gap-2 sm:hidden">
          {howItWorksSlides.map((_, i) => (
            <div
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              key={i}
              className={cn(
                'h-2 w-2 rounded-full bg-white',
                i === slideIndex ? 'opacity-100' : 'opacity-50',
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

type HowItWorksStepProps = {
  image: React.ReactNode;
  title: string;
  description: string;
  className?: string;
};
export function HowItWorksStep(props: HowItWorksStepProps) {
  const { title, description, image, className } = props;

  return (
    <div
      className={cn(
        'flex min-w-full snap-start flex-col gap-2 rounded-lg bg-white px-6 py-12 text-black sm:min-w-0',
        className,
      )}
    >
      <p className="flex items-center gap-2 font-semibold text-xl md:text-2xl">
        {image} {title}
      </p>
      <div className="h-1" />
      <p className="leading-5">{description}</p>
    </div>
  );
}

const onboardingSchema = z.object({
  address: z.string(),
  from: z.coerce.date(),
  to: z.coerce.date(),
});

function OnboardingForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();

  const [noListingDialogData, setNoListingDialogData] = useState<
    NoListingsDialogProps['requestData'] | null
  >(null);

  const verifyAvailabilityMutation = useMutation({
    mutationKey: ['verify-availability'],
    mutationFn: async (data: z.infer<typeof onboardingSchema>) => {
      const response = await postCheckListingsAvailability(data);

      if (response.status === 'exists') {
        navigate(response.to);
        return;
      }

      if (response.status === 'error') {
        toast.error(response.error);
        return;
      }

      if (response.status === 'no-listings') {
        setNoListingDialogData(response.data);
        analytics?.track('Guest Landing No Listings Found', response.data);
        return;
      }
    },
  });

  return (
    <>
      {!!noListingDialogData && (
        <NoListingsDialog
          requestData={noListingDialogData}
          onOpenChange={(open) => {
            if (!open) {
              setNoListingDialogData(null);
            }
          }}
        />
      )}
      <Form
        ref={formRef}
        className="relative min-h-[44px] w-full rounded-full bg-white text-start font-semibold text-gray-600 text-lg shadow-outer-border hover:bg-gray-100"
        onSubmit={(e) => {
          e.preventDefault();
          if (!formRef.current) {
            return;
          }

          const formData = new FormData(formRef.current);
          const result = parseWithZod(formData, { schema: onboardingSchema });

          if (!formData.has('address')) {
            (formRef.current.querySelector('#address-trigger') as HTMLElement)?.click();
            return;
          }

          if (!formData.has('from') || !formData.has('to')) {
            (formRef.current.querySelector('#dates-trigger') as HTMLElement)?.click();
            return;
          }

          if (result.status !== 'success') {
            return;
          }

          verifyAvailabilityMutation.mutate(result.value);
        }}
      >
        <div className="pointer-events-none absolute inset-0 z-20 grid grid-cols-[1fr,44px] items-center sm:grid-cols-[1fr,2fr,44px]">
          <Button
            type="submit"
            variant="gradient"
            aria-label="Search"
            disabled={verifyAvailabilityMutation.isPending}
            className="pointer-events-auto ml-[2px] flex h-[38px] w-[38px] items-center justify-center rounded-full p-0 [grid-column:2] sm:[grid-column:3]"
          >
            <Icon name="magnifying-glass" className="h-6 w-6" />
          </Button>
        </div>
        <AddressField />
        <DatesField />
      </Form>
    </>
  );
}

type RequestData = {
  address: string;
  from: Date | string;
  to: Date | string;
};

type NoListingsDialogProps = {
  onOpenChange: (open: boolean) => void;

  requestData: RequestData;
};

type NoListingsDialogSlide =
  | {
      type: 'initial';
      requestData: RequestData;
    }
  | {
      type: 'form';
      requestData: RequestData;
    }
  | {
      type: 'verify';
      requestData: RequestData & {
        email: string;
      };
    }
  | {
      type: 'success';
    };

export const NoListingsDialog: FC<NoListingsDialogProps> = (props) => {
  const { requestData, onOpenChange } = props;

  const [slide, setSlide] = useState<NoListingsDialogSlide>({
    type: 'initial',
    requestData,
  });

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        onOpenChange(open);
      }}
    >
      <DialogContent
        title="No listings found"
        noClose
        className="flex max-w-2xl flex-col items-center justify-center gap-10 rounded-md p-4 transition-[width] max-sm:h-[480px] max-sm:w-[calc(100%-2rem)] sm:p-12 md:w-fit"
      >
        {slide.type === 'initial' && (
          <>
            <div className="h-20 w-20 rounded-lg bg-border/50 p-3 shadow-md">
              <img src="/img/icons/rocket.png" alt="rocket" className="h-full w-full" />
            </div>
            <div className="flex w-full flex-col gap-4">
              <p className="font-semibold text-xl sm:text-2xl">We’re not there just yet!</p>
              <p>
                Unfortunately, we don’t have listings that match your preferences at the moment, but
                we’re always expanding! Submit a request below, and we’ll notify you as soon as
                we’re there!
              </p>
            </div>
            <Button
              variant="green"
              className="px-12 text-lg"
              onClick={() =>
                setSlide({
                  type: 'form',
                  requestData: slide.requestData,
                })
              }
            >
              Request
            </Button>
          </>
        )}
        {slide.type === 'form' && (
          <NoListingRequestForm
            address={slide.requestData.address}
            from={slide.requestData.from}
            to={slide.requestData.to}
            onSuccess={(newData) => {
              setSlide({
                type: 'verify',
                requestData: newData,
              });
            }}
          />
        )}
        {slide.type === 'verify' && (
          <NoListingVerifyForm
            requestData={slide.requestData}
            onSuccess={() => {
              setSlide({
                type: 'success',
              });
            }}
          />
        )}
        {slide.type === 'success' && (
          <div className="flex flex-row items-center gap-8">
            <div className="size-24 min-w-24 rounded-lg bg-gray-100 p-5">
              <img src="/img/icons/request-received.png" alt="check" className="size-full" />
            </div>
            <div>
              <p className="font-semibold text-2xl">Request received!</p>
              <p className="text-base">
                Thank you for sharing your preferences and joining our student community. We’ve got
                your request and will let you know as soon as we have listings that match!
              </p>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const emailSchema = z.string().email();

type NoListingRequest = {
  address: string;
  from: Date | string;
  to: Date | string;
  email: string;
};

type NoListingRequestFormProps = {
  address: string;
  from: Date | string;
  to: Date | string;
  onSuccess: (data: NoListingRequest) => void;
};
export const NoListingRequestForm: FC<NoListingRequestFormProps> = (props) => {
  const { address, from, to, onSuccess } = props;

  const requestMutation = useMutation({
    mutationKey: ['request-listings-availability'],
    mutationFn: async (email: string) => {
      const validationResult = emailSchema.safeParse(email);
      if (!validationResult.success) {
        return {
          status: 'error' as const,
          error: 'Invalid email',
        };
      }
      const result = await requestListingsAvailability({ email });

      if (result.status === 'error') {
        return { status: 'error' as const, error: result.error };
      }

      if (result.status === 'verification-sent') {
        onSuccess({ email, address, from, to });
        return { status: 'verification-sent' as const };
      }

      return null;
    },
  });

  const [email, setEmail] = useState('');

  const noEmail = !email;

  return (
    <>
      <DialogTitle className="w-full text-start text-2xl">Your request</DialogTitle>
      <div className="flex max-w-full flex-col gap-2 text-black">
        <div>
          <p className="ml-2 text-lg">Location</p>

          <div className="flex h-[45px] flex-row items-center gap-4 rounded-md border border-border px-2">
            <img src="/img/icons/map-marker.png" alt="map-marker" className="size-8" />
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg">
              {address}
            </span>
          </div>
        </div>
        <div>
          <p className="ml-2 text-lg">Dates</p>

          <div className="flex h-[45px] flex-row items-center gap-4 rounded-md border border-border px-2">
            <img src="/img/icons/calendar.png" alt="calendar" className="size-8" />
            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg">
              <FormatDate date={from} format="PP" /> - <FormatDate date={to} format="PP" />
            </span>
          </div>
        </div>
        <div>
          <p className="ml-2 text-lg">Email</p>

          <div className="relative flex items-center justify-center">
            <Input
              className={cn(
                'h-[45px] pl-14 text-lg transition-colors',
                noEmail && 'border-primary font-normal placeholder:text-primary',
              )}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="ava@u.northwestern.edu"
            />
            <img src="/img/icons/mail.png" alt="mail" className="absolute left-2 size-8" />
          </div>
          <ErrorList
            errors={requestMutation.data?.status === 'error' ? [requestMutation.data.error] : []}
          />
        </div>
      </div>
      <DialogFooter>
        <StatusButton
          variant="default"
          status={requestMutation.isPending ? 'pending' : 'idle'}
          disabled={requestMutation.isPending}
          className="px-12 text-lg"
          onClick={() => requestMutation.mutate(email)}
        >
          Submit
        </StatusButton>
      </DialogFooter>
    </>
  );
};

type NoListingVerifyFormProps = {
  requestData: NoListingRequest & {
    email: string;
  };
  onSuccess: () => void;
};
export const NoListingVerifyForm: FC<NoListingVerifyFormProps> = (props) => {
  const { requestData, onSuccess } = props;

  const [otp, setOtp] = useState('');

  const verifyMutation = useMutation({
    mutationKey: ['verify-listings-availability'],
    mutationFn: async (otp: string) => {
      const result = await verifyListingsAvailability({
        ...requestData,
        code: otp,
        from: requestData.from as Date,
        to: requestData.to as Date,
      });
      if (result.status === 'success') {
        onSuccess();
      }
      return result;
    },
  });

  return (
    <>
      <div>
        <p className="font-semibold text-2xl">Verify now</p>
        <p className="text-base text-gray-500">
          Please enter the six digit code that was sent to you.
        </p>
      </div>
      <div className="min-h-4" />
      <InputOTP maxLength={6} value={otp} onChange={setOtp}>
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      <div className="min-h-4">
        <ErrorList
          errors={verifyMutation.data?.status === 'error' ? [verifyMutation.data.error] : []}
        />
      </div>
      <Button
        variant="gradient"
        className="px-12 text-lg"
        onClick={() => verifyMutation.mutate(otp)}
        disabled={verifyMutation.isPending}
      >
        Verify
      </Button>
    </>
  );
};

function AddressField() {
  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState<string | undefined>();

  return (
    <div className="pointer-events-none absolute inset-0 z-10 grid grid-cols-[1fr,,44px] sm:grid-cols-[1fr,2px,1fr,2px,1fr,44px]">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          id="address-trigger"
          className="pointer-events-auto justify-items-start gap-2 overflow-hidden pl-6"
        >
          {address && (
            <input type="hidden" name="address" value={address} readOnly className="hidden" />
          )}
          {address ? (
            <div className="overflow-hidden text-ellipsis whitespace-nowrap text-center text-base">
              {address}
            </div>
          ) : (
            <div className="text-start text-base sm:text-center">Anywhere</div>
          )}
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="flex items-center justify-start gap-6 overflow-hidden rounded-3xl bg-white px-2 py-2 max-sm:w-[70vw] sm:w-[480px]"
        >
          <AddressFieldContent onAddressSelected={setAddress} />
        </PopoverContent>
      </Popover>
    </div>
  );
}

type AddressFieldContentProps = {
  onAddressSelected: (address: string) => void;
};
function AddressFieldContent({ onAddressSelected }: AddressFieldContentProps) {
  const { isLoaded } = useGoogleMapJsLoader();

  if (!isLoaded) {
    return <AddressFieldinput />;
  }

  return <AddressFieldAutocomplete onAddressSelected={onAddressSelected} />;
}

function AddressFieldinput(props: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className="flex w-full items-center justify-start gap-2 pr-2 pl-2">
      <Icon name="map-pin" className="h-8 w-8 text-gray-500" />
      <Input
        autoFocus
        variant="ghost"
        type="text"
        form="location-autocomplete"
        name="location-autocomplete-internal"
        autoComplete="off"
        className="m-1"
        placeholder="Chicago, IL"
        {...props}
      />
    </div>
  );
}

function AddressFieldAutocomplete({ onAddressSelected }: AddressFieldContentProps) {
  const { predictions, fetchPredictions, resetPredictions } = useLocationAutocompletePredictions();
  const { fetchPlace } = useLocationAutocompletePlaces();

  const [input, setInput] = useState('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const input = e.currentTarget.value;
    setInput(input);
    fetchPredictions(input);
  };

  const handlePredictionSelected = async (
    prediction: google.maps.places.AutocompletePrediction,
  ) => {
    const placeId = prediction.place_id;
    if (!placeId) {
      return;
    }

    const place = await fetchPlace(placeId);
    if (!place || !place.formatted_address) {
      return;
    }

    setInput(place.formatted_address);
    resetPredictions();
    onAddressSelected(place.formatted_address);
  };

  const { autocompleteOnKey, setSelected, selected } = useAutocompleteArrowsInput({
    predictions,
    handlePredictionSelected,
  });

  return (
    <div className="flex w-full flex-col items-start gap-2 overflow-hidden">
      <AddressFieldinput value={input} onChange={handleInputChange} onKeyDown={autocompleteOnKey} />
      {!!predictions &&
        predictions.length > 0 &&
        predictions.map((prediction, i) => (
          <button
            key={prediction.place_id}
            className={cn(
              'block w-full select-none overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-2 py-1.5 text-start text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
              selected === i && 'bg-accent text-accent-foreground',
            )}
            onMouseDown={(e) => {
              e.preventDefault();
              setSelected(i);
              handlePredictionSelected(prediction);
            }}
          >
            {prediction.description}
          </button>
        ))}
    </div>
  );
}

function DatesField() {
  const [selected, setSelected] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  return (
    <Popover>
      <PopoverTrigger
        id="dates-trigger"
        role="button"
        aria-label="Dates trigger"
        className="absolute inset-0 grid grid-cols-[1fr,44px] items-center justify-items-center gap-2 pl-4 sm:grid-cols-[1fr,2px,1fr,2px,1fr,44px]"
        asChild
      >
        <div>
          {selected.from && (
            <input
              type="hidden"
              name="from"
              value={selected.from.toISOString()}
              readOnly
              className="hidden"
            />
          )}
          {selected.to && (
            <input
              type="hidden"
              name="to"
              value={selected.to.toISOString()}
              readOnly
              className="hidden"
            />
          )}
          <div className="pointer-events-none" />
          <span className="mt-[2px] h-[38px] w-[1px] bg-gray-200" />
          <div className="hidden py-2 text-center text-base sm:block">
            {selected.from ? <FormatDate date={selected.from} format="PP" /> : 'Check-in'}
          </div>
          <span className="mt-[2px] hidden h-[38px] w-[1px] bg-gray-200 sm:inline" />
          <div className="hidden py-2 pr-2 text-center text-base sm:block">
            {selected.to ? <FormatDate date={selected.to} format="PP" /> : 'Check-out'}
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        className="mr-20 flex w-auto min-w-[var(--radix-popover-trigger-width)] justify-center gap-2 rounded-3xl p-2 pt-4"
      >
        <div>
          <p className="hidden pb-2 pl-2 font-semibold sm:block">Check-in</p>
          <Calendar
            compact={true}
            mode="range"
            selected={selected}
            className="bg-white p-0"
            onSelect={(e) => e && setSelected(e)}
          />
        </div>
        <div className="hidden sm:block">
          <p className="pb-2 pl-2 font-semibold">Check-out</p>
          <Calendar
            compact={true}
            mode="range"
            selected={selected}
            className="bg-white p-0"
            onSelect={(e) => e && setSelected(e)}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
